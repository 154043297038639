
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import ConnectAccountButton from './ConnectAccountButton.vue'
import DisconnectAccountButton from './DisconnectAccountButton.vue'
import { useAxios } from '@/composables/useAxios'
import PagesList from './PagesList.vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'

interface PageProps {
  id: string
  name: string
  picture: any
  source: string
  username?: string
  link?: string
}

export default defineComponent({
  components: {
    PagesList,
    BaseModal,
    ConnectButton,
    ConnectAccountButton,
    DisconnectAccountButton,
  },
  setup () {
    const facebookPagesList = ref<PageProps[]>([])
    const selectedFacebookPage = ref<PageProps>()
    const selectedInstagramPage = ref<PageProps>()
    const hasFacebookPages = computed(() => facebookPagesList.value.length > 0)
    const hasFacebookSelectedPage = computed(() => selectedFacebookPage.value !== undefined)
    const hasInstagramSelectedPage = computed(() => selectedInstagramPage.value !== undefined)
    const selectedPageId = ref<string>('')
    const isConnectingAccount = ref(false)

    const { profile } = useProfile()
    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    const getSocialButton = (source: string) => {
      return socialButtons.value.find(button => button.source === source)
    }

    const facebookImage = computed(() => require('@/assets/img/facebook-logo-full.png'))
    const instagramImage = computed(() => require('@/assets/img/instagram-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isFacebookConnected,
      setActive: setFacebookConnected,
      setInactive: setFacebookDisconnected,
    } = useToggle(false)

    const {
      isActive: isInstagramConnected,
      setActive: setInstagramConnected,
      setInactive: setInstagramDisconnected,
    } = useToggle(false)
    
    const handleCompleteAction = async () => {
      if (selectedPageId.value) {
        isConnectingAccount.value = true
        const { httpFunctions } = await useAxios()
        await httpFunctions.put(`/socialLogin-requests/select-page`, {
          source: 'facebook',
          profileId: profile.value.id,
          pageId: selectedPageId.value
        })
      }

      isConnectingAccount.value = false
      closeModal()
    }

    const unselectPage = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/unselect-page`, {
        source: 'facebook',
        profileId: profile.value.id
      })
    }

    const restart = () => {
      setFacebookDisconnected()
      selectedFacebookPage.value = undefined
      facebookPagesList.value = []

      setInstagramDisconnected()
      selectedInstagramPage.value = undefined

      selectedPageId.value = ''
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedFacebookPage.value = undefined
          facebookPagesList.value = []

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { facebook, instagram } = accountsData

              if (facebook) {
                setFacebookConnected()

                if (facebook.pages) {
                  facebookPagesList.value = [...facebook.pages]
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                    .map((page) => {
                      const picture = ('picture' in page) ? page.picture.data.url : null
                      const connectedAccount = {
                        id: page.connected_instagram_account?.id || '',
                        name: page.connected_instagram_account?.name || 'Sem Instagram',
                        picture: page.connected_instagram_account?.profile_picture_url || require('@/assets/profile/manyrobot-profile.svg'),
                        username: page.connected_instagram_account?.username ? '@' + page.connected_instagram_account.username : 'Não há uma conta business do Instagram associada a esta página do Facebook',
                        source: 'instagram'
                      }

                      return {
                        id: page.id,
                        name: page.name,
                        picture,
                        username: page.username,
                        source: 'facebook',
                        link: page.link,
                        ...(connectedAccount && {connectedAccount})
                      }
                    })
                }

                if (facebook.selectedPage) {
                  selectedFacebookPage.value = {
                    id: facebook.selectedPage.id,
                    name: facebook.selectedPage.name,
                    picture: facebook.selectedPage.pictureStorage,
                    username: facebook.selectedPage.username,
                    source: 'facebook',
                    link: facebook.selectedPage.link,
                  }
                }

                if (instagram && instagram.selectedPage) {
                  setInstagramConnected()
                  selectedInstagramPage.value = {
                    id: instagram.selectedPage.id,
                    name: instagram.selectedPage.name,
                    picture: instagram.pictureStorage,
                    username: instagram.selectedPage.username,
                    source: 'instagram'
                  }
                }
              }
            }
            return
          }

          restart()
        })
    })

    return {
      selectedPageId,
      handleCompleteAction,
      hasPages: hasFacebookPages,
      hasFacebookSelectedPage,
      facebookImage,
      instagramImage,
      hasInstagramSelectedPage,
      isFacebookConnected,
      isInstagramConnected,
      isOpen,
      isConnectingAccount,
      pagesList: facebookPagesList,
      getSocialButton,
      selectedInstagramPage,
      selectedFacebookPage,
      setConnected: setFacebookConnected,
      unselectPage,
      closeModal,
      restart
    }
  },
})
