<template>
  <base-modal
    hide-footer
    v-model:open="isOpen"
    size="medium"
  >
    <template v-slot:button>
      <connect-button
        :accounts="[{
          image: facebookImage,
          connected: hasFacebookSelectedPage
        },
        {
          image: instagramImage,
          connected: hasInstagramSelectedPage
        }]"
      />
    </template>

    <div class="card-header">
      <div class="card-title">
        {{ $t('page.settings.social_accounts.sentence.connect_social_account-dynamic', { source: 'Facebook/Instagram' }) }}
      </div>
      <div class="flex justify-start items-center">
        <button @click.prevent="closeModal" class="px-2">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
    <div class="card-body">
      <div v-if="isFacebookConnected">
        <!-- Show connected account -->
        <div v-if="hasFacebookSelectedPage"
          class="grid grid-cols-1 md:grid-cols-2"
        >
          <div v-if="hasFacebookSelectedPage">
            <div class="font-semibold mt-8 mb-8 text-center">
              {{ $t('page.settings.social_accounts.sentence.you_are_connected_in-dynamic', { source: 'Facebook' }) }}
            </div>

            <div class="flex flex-col items-center my-12">
              <div class="flex-none w-18 relative">
                <img :src="selectedFacebookPage?.picture" class="w-16 h-16 rounded-full" />
                <div
                  class="select-button absolute right-0 bottom-0"
                  :style="getSocialButton(selectedFacebookPage?.source)?.style || {}"
                >
                  <i
                    class="fab text-white"
                    :class="getSocialButton(selectedFacebookPage?.source)?.class || {}"
                  >
                  </i>
                </div>
              </div>
              <div class="font-semibold text-center">
                {{ selectedFacebookPage?.name }}
              </div>
              <div class="text-xs text-gray-500">
                {{ selectedFacebookPage?.link }}
              </div>

              <a
                class="mt-4"
                href="javascript:void(0)"
                title="Conectar outra página"
                @click.prevent="unselectPage"
              >
                {{ $t('page.settings.social_accounts.sentence.change_social_account_page') }}
              </a>
            </div>
          </div>
          <div v-if="hasInstagramSelectedPage">
            <div class="font-semibold mt-8 mb-8 text-center">
              {{ $t('page.settings.social_accounts.sentence.you_are_connected_in-dynamic', { source: 'Instagram' }) }}
            </div>

            <div class="flex flex-col items-center my-12">
              <div class="flex-none w-18 relative">
                <img :src="selectedInstagramPage?.picture" class="w-16 h-16 rounded-full" />
                <div
                  class="select-button absolute right-0 bottom-0"
                  :style="getSocialButton(selectedInstagramPage?.source)?.style || {}"
                >
                  <i
                    class="fab text-white"
                    :class="getSocialButton(selectedInstagramPage?.source)?.class || {}"
                  >
                  </i>
                </div>
              </div>
              <div class="font-semibold text-center">
                {{ selectedInstagramPage?.name }}
              </div>
              <div class="text-xs text-gray-500">
                @{{ selectedInstagramPage?.username }}
              </div>
            </div>
          </div>
        </div>
        <!-- Pages list -->
        <div v-else-if="hasPages">
          <div class="mt-4 mb-8">
            {{ $t('page.settings.social_accounts.sentence.select_new_page') }}
          </div>
          <pages-list
            :pagesList="pagesList"
            :sources="['Facebook', 'Instagram']"
            v-model="selectedPageId"
          />
        </div>
        <!-- Account with empty pages list -->
        <div
          class="mt-4"
          v-else
        >
          <div
            v-html="$t('page.settings.social_accounts.sentence.empty_facebook_page_list-html')"
          />

          <div class="text-sm text-center mt-12">
            {{ $t('sentence.you_need_help') }}
            <a class="text-blue-600" href="https://suporte.manycontent.com/faq/article/como-conectar-a-minha-pagina-do-facebook" target="_blank">{{ $t('action.support_team') }}</a>
          </div>
        </div>
      </div>
      <!-- Account not connected -->
      <div v-else>
        <div class="mt-8 mb-12">
          {{ $t('page.settings.social_accounts.sentence.connect_social_account_message-dynamic', { source: 'Facebook/Instagram' }) }}
        </div>
      </div>
    </div>
    <div
      class="card-footer flex justify-between items-center space-x-2"
    >
      <div class="flex justify-start items-center space-x-2">
        <disconnect-account-button source="facebook"
          v-if="isFacebookConnected"
          @disconnected="restart"
        />
      </div>
      <div class="flex justify-end items-center space-x-2">
        <connect-account-button source="facebook" v-if="!isFacebookConnected"/>
        <button
          v-if="isFacebookConnected && !hasFacebookSelectedPage"
          class="btn btn-primary"
          :disabled="!selectedPageId"
          @click.prevent="handleCompleteAction"
        >
        {{ $t('page.settings.social_accounts.sentence.connect_social_account-dynamic', { source: 'Facebook / Instagram' }) }}

          <i class="fas fa-spinner fa-spin !mr-0 ml-4" v-if="isConnectingAccount"></i>
          <i class="fas fa-arrow-right !mr-0 ml-4" v-else></i>
        </button>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, unref } from 'vue'
import { firestore } from '@/plugins/firebase'
import { useProfile } from '@/composables/profile/useProfile'
import { useToggle } from '@/composables/useToggle'
import BaseModal from '../base/BaseModal.vue'
import ConnectButton from './ConnectButton.vue'
import ConnectAccountButton from './ConnectAccountButton.vue'
import DisconnectAccountButton from './DisconnectAccountButton.vue'
import { useAxios } from '@/composables/useAxios'
import PagesList from './PagesList.vue'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'

interface PageProps {
  id: string
  name: string
  picture: any
  source: string
  username?: string
  link?: string
}

export default defineComponent({
  components: {
    PagesList,
    BaseModal,
    ConnectButton,
    ConnectAccountButton,
    DisconnectAccountButton,
  },
  setup () {
    const facebookPagesList = ref<PageProps[]>([])
    const selectedFacebookPage = ref<PageProps>()
    const selectedInstagramPage = ref<PageProps>()
    const hasFacebookPages = computed(() => facebookPagesList.value.length > 0)
    const hasFacebookSelectedPage = computed(() => selectedFacebookPage.value !== undefined)
    const hasInstagramSelectedPage = computed(() => selectedInstagramPage.value !== undefined)
    const selectedPageId = ref<string>('')
    const isConnectingAccount = ref(false)

    const { profile } = useProfile()
    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value).map(obj => {
        return {
          source: obj[0],
          ...obj[1]
        }
      })
    })

    const getSocialButton = (source: string) => {
      return socialButtons.value.find(button => button.source === source)
    }

    const facebookImage = computed(() => require('@/assets/img/facebook-logo-full.png'))
    const instagramImage = computed(() => require('@/assets/img/instagram-logo-full.png'))

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const {
      isActive: isFacebookConnected,
      setActive: setFacebookConnected,
      setInactive: setFacebookDisconnected,
    } = useToggle(false)

    const {
      isActive: isInstagramConnected,
      setActive: setInstagramConnected,
      setInactive: setInstagramDisconnected,
    } = useToggle(false)
    
    const handleCompleteAction = async () => {
      if (selectedPageId.value) {
        isConnectingAccount.value = true
        const { httpFunctions } = await useAxios()
        await httpFunctions.put(`/socialLogin-requests/select-page`, {
          source: 'facebook',
          profileId: profile.value.id,
          pageId: selectedPageId.value
        })
      }

      isConnectingAccount.value = false
      closeModal()
    }

    const unselectPage = async () => {
      const { httpFunctions } = await useAxios()
      await httpFunctions.put(`/socialLogin-requests/unselect-page`, {
        source: 'facebook',
        profileId: profile.value.id
      })
    }

    const restart = () => {
      setFacebookDisconnected()
      selectedFacebookPage.value = undefined
      facebookPagesList.value = []

      setInstagramDisconnected()
      selectedInstagramPage.value = undefined

      selectedPageId.value = ''
    }

    onMounted(async () => {
      firestore.collection('socialAccounts')
        .doc(unref(profile).id)
        .onSnapshot((accountsDoc) => {
          selectedFacebookPage.value = undefined
          facebookPagesList.value = []

          if (accountsDoc.exists) {
            const accountsData = accountsDoc.data()

            if (accountsData) {
              const { facebook, instagram } = accountsData

              if (facebook) {
                setFacebookConnected()

                if (facebook.pages) {
                  facebookPagesList.value = [...facebook.pages]
                    .sort((a, b) => a.name > b.name ? 1 : -1)
                    .map((page) => {
                      const picture = ('picture' in page) ? page.picture.data.url : null
                      const connectedAccount = {
                        id: page.connected_instagram_account?.id || '',
                        name: page.connected_instagram_account?.name || 'Sem Instagram',
                        picture: page.connected_instagram_account?.profile_picture_url || require('@/assets/profile/manyrobot-profile.svg'),
                        username: page.connected_instagram_account?.username ? '@' + page.connected_instagram_account.username : 'Não há uma conta business do Instagram associada a esta página do Facebook',
                        source: 'instagram'
                      }

                      return {
                        id: page.id,
                        name: page.name,
                        picture,
                        username: page.username,
                        source: 'facebook',
                        link: page.link,
                        ...(connectedAccount && {connectedAccount})
                      }
                    })
                }

                if (facebook.selectedPage) {
                  selectedFacebookPage.value = {
                    id: facebook.selectedPage.id,
                    name: facebook.selectedPage.name,
                    picture: facebook.selectedPage.pictureStorage,
                    username: facebook.selectedPage.username,
                    source: 'facebook',
                    link: facebook.selectedPage.link,
                  }
                }

                if (instagram && instagram.selectedPage) {
                  setInstagramConnected()
                  selectedInstagramPage.value = {
                    id: instagram.selectedPage.id,
                    name: instagram.selectedPage.name,
                    picture: instagram.pictureStorage,
                    username: instagram.selectedPage.username,
                    source: 'instagram'
                  }
                }
              }
            }
            return
          }

          restart()
        })
    })

    return {
      selectedPageId,
      handleCompleteAction,
      hasPages: hasFacebookPages,
      hasFacebookSelectedPage,
      facebookImage,
      instagramImage,
      hasInstagramSelectedPage,
      isFacebookConnected,
      isInstagramConnected,
      isOpen,
      isConnectingAccount,
      pagesList: facebookPagesList,
      getSocialButton,
      selectedInstagramPage,
      selectedFacebookPage,
      setConnected: setFacebookConnected,
      unselectPage,
      closeModal,
      restart
    }
  },
})
</script>
